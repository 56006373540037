var __jsx = React.createElement;
import React from 'react';
import HomeDetail from "../components/Home";
import withPageMeta from "../hocs/withPageMeta";
var DEFAULT_TITLE = 'Qantas Shopping | Shop Brands Online - Earn Points';
var DEFAULT_DESCRIPTION = 'Earn Qantas Points shopping online at Myer, ebay, THE ICONIC, Apple, Adore Beauty, rebel and many more. Shop online now!';
var HomePage = function HomePage() {
  return __jsx(HomeDetail, null);
};
export default withPageMeta(HomePage, {
  title: DEFAULT_TITLE,
  description: DEFAULT_DESCRIPTION
});