import moment from 'moment';
import { getFilteredOffers } from "../components/CardOffers/mcoUtils";
var getMcoForCarousel = function getMcoForCarousel(mcoOffers) {
  if (!mcoOffers) {
    return {
      activeMcoOffers: [],
      filteredMco: []
    };
  }
  var activeMcoOffers = mcoOffers.filter(function (o) {
    return moment(o.eventEndDate).isSameOrAfter(moment(), 'day');
  });
  var filteredMco = getFilteredOffers(activeMcoOffers, {
    sortOrder: 'start-date'
  }).slice(0, 10);
  return {
    activeMcoOffers: activeMcoOffers,
    filteredMco: filteredMco
  };
};
export default getMcoForCarousel;