var __jsx = React.createElement;
import React from 'react';
import { fontSizes, headingSizes, gradients, responsiveQuery, customFonts, colours } from "../../constants/css";
import styled from 'styled-components';
var imgSrc = "/static/plugin/plugin-laptop.png";
import { Container, Button } from "../_global";
import InstallAvailability from "./InstallAvailability";
var Wrapper = styled.section.withConfig({
  displayName: "Banner__Wrapper",
  componentId: "sc-dn50g5-0"
})(["background:", ";padding:40px 0 50px 0;h2{font-family:", ";font-size:1.625rem;font-weight:500;line-height:1.22;margin:0;}@media only screen and ", "{padding:50px 0 60px 0;h2{font-size:", ";}}"], gradients.whiteToGray, customFonts.ciutadellaMed, responsiveQuery.tabletAndDesktop, headingSizes.h1);
var InnerContainer = styled(Container).withConfig({
  displayName: "Banner__InnerContainer",
  componentId: "sc-dn50g5-1"
})(["margin:0 auto;@media only screen and ", "{max-width:380px;}@media only screen and ", "{display:flex;align-items:center;}"], responsiveQuery.mobileAndTablet, responsiveQuery.tabletAndDesktop);
var ImageSection = styled.div.withConfig({
  displayName: "Banner__ImageSection",
  componentId: "sc-dn50g5-2"
})(["img{max-width:90%;}@media only screen and ", "{flex-basis:50%;padding-top:30px;}"], responsiveQuery.tabletAndDesktop);
var Availability = styled.div.withConfig({
  displayName: "Banner__Availability",
  componentId: "sc-dn50g5-3"
})(["margin-top:24px;color:", ";@media only screen and ", "{max-width:185px;span{margin-top:10px;}span:first-child{margin-left:0;}}@media only screen and ", "{span:first-child{margin-left:20px;}}"], colours.qantasCharcoal, responsiveQuery.mobileAndTablet, responsiveQuery.tabletAndDesktop);
var CopySection = styled.div.withConfig({
  displayName: "Banner__CopySection",
  componentId: "sc-dn50g5-4"
})(["@media only screen and ", "{flex-basis:50%;}"], responsiveQuery.tabletAndDesktop);
var Copy = styled.p.withConfig({
  displayName: "Banner__Copy",
  componentId: "sc-dn50g5-5"
})(["color:", ";margin:14px 0 20px;line-height:1.5;@media only screen and ", "{font-size:", ";margin:20px 0 30px;}"], colours.qantasGrey40, responsiveQuery.tabletAndDesktop, fontSizes.large);
var ButtonStyled = styled(Button).withConfig({
  displayName: "Banner__ButtonStyled",
  componentId: "sc-dn50g5-6"
})(["@media only screen and ", "{width:100%;display:block;}@media only screen and ", "{min-width:185px;}"], responsiveQuery.mobileAndTablet, responsiveQuery.tabletAndDesktop);
var Banner = function Banner() {
  return __jsx(Wrapper, null, __jsx(InnerContainer, null, __jsx(ImageSection, null, __jsx("img", {
    src: imgSrc,
    alt: ""
  })), __jsx(CopySection, null, __jsx("h2", null, "Would you like ", __jsx("br", null), "Qantas Points with that?"), __jsx(Copy, null, "Get the Qantas Shopping Points-Prompter\xA0 to be alerted of points-earning opportunities whilst shopping online."), __jsx(ButtonStyled, {
    pageLink: "/points-prompter",
    primary: true
  }, "find out more"), __jsx(Availability, null, __jsx(InstallAvailability, null)))));
};
export default Banner;