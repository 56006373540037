import styled from 'styled-components';
import { colours, responsiveQuery, fontSizes, customFonts } from "../../constants/css";
export var CategorySection = styled.div.withConfig({
  displayName: "style__CategorySection",
  componentId: "sc-w9joi0-0"
})(["background-color:", ";margin-top:1rem;padding-bottom:1rem;"], colours.qantasMediumGrey);
export var CategorySectionTitle = styled.span.withConfig({
  displayName: "style__CategorySectionTitle",
  componentId: "sc-w9joi0-1"
})(["margin-left:1rem;font-family:", ";font-size:", ";font-weight:normal;font-style:normal;font-stretch:normal;letter-spacing:normal;color:", ";margin-bottom:10px;margin-top:10px;"], customFonts.ciutadellaMed, fontSizes.large, colours.qantasCharcoal);
export var ViewCategory = styled.span.withConfig({
  displayName: "style__ViewCategory",
  componentId: "sc-w9joi0-2"
})(["position:absolute;margin-top:-14px;right:0;"]);
export var CategoryHeaderWrapper = styled.div.withConfig({
  displayName: "style__CategoryHeaderWrapper",
  componentId: "sc-w9joi0-3"
})(["padding:0 1rem;display:flex;justify-content:space-between;a{margin-top:18px;color:", ";font-size:", ";position:relative;text-decoration:none;svg.arrow-red-right{position:relative;top:1px;margin:0 9px;}i.right-arrow{margin-left:2px;}@media only screen and ", "{font-size:", ";margin-top:2rem;text-decoration:underline;}}"], colours.qantasRed, fontSizes.base, responsiveQuery.largerThanMobileNew, fontSizes.small);
export var CategoryBannerText = styled.h1.withConfig({
  displayName: "style__CategoryBannerText",
  componentId: "sc-w9joi0-4"
})(["font-family:", ";font-size:", ";font-weight:normal;font-style:normal;font-stretch:normal;line-height:normal;letter-spacing:normal;color:#282b26;position:relative;svg{position:absolute;margin-left:6px;}@media only screen and ", "{font-size:", ";}"], customFonts.ciutadellaMed, fontSizes.xl1, responsiveQuery.tabletAndDesktop, fontSizes.xxxl);