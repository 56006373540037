import React from "react";
var __jsx = React.createElement;
import { useEffect, useState } from 'react';
import Router from 'next/router';
import { connect } from 'react-redux';
import { ButtonTransparent } from "../_global";
import { CloseIcon } from "../_global/Icons";
import { colours } from "../../constants/css";
import EventTrackingHelper from "../../utils/trackingUtilities";
import { getBonusBannerCalloutCookie, setBonusBannerCalloutCookie } from "../../utils/sessionHelper";
import { FavouritesOnBonusBanner } from "./style";
var BonusBanner = function BonusBanner(props) {
  var _useState = useState(false),
    show = _useState[0],
    setShow = _useState[1];
  var favouritesOnBonus = props.favouritesOnBonus,
    authorized = props.authorized;
  var handleCookie = function handleCookie() {
    var cookieExpiration = new Date();
    cookieExpiration.setDate(cookieExpiration.getDate() + 1);
    cookieExpiration.setHours(0);
    cookieExpiration.setMinutes(0);
    cookieExpiration.setSeconds(0);
    setBonusBannerCalloutCookie(cookieExpiration);
  };
  useEffect(function () {
    var hideBanner = getBonusBannerCalloutCookie();
    if (!hideBanner && authorized && favouritesOnBonus) {
      setShow(true);
      var timeout = setTimeout(function () {
        setShow(false);
      }, 5000);
      handleCookie();
      return function () {
        clearTimeout(timeout);
      };
    }
  }, [authorized, favouritesOnBonus]);
  return __jsx(FavouritesOnBonusBanner, {
    className: "bonusBanner",
    onClick: function onClick() {
      EventTrackingHelper.trackGenericAction('favourite bonus|view');
      Router.push('/favourites');
    },
    show: show
  }, __jsx("p", null, "Great news, one of your favourite brands is on bonus"), __jsx(ButtonTransparent, {
    className: "bonusBanner-close",
    onClick: function onClick(e) {
      e.stopPropagation();
      EventTrackingHelper.trackGenericAction('favourite bonus|closed');
      handleCookie();
      setShow(false);
    }
  }, __jsx(CloseIcon, {
    width: 11,
    height: 11,
    color: colours.qantasCharcoal
  })));
};
var mapStateToProps = function mapStateToProps(_ref) {
  var user = _ref.user;
  return {
    authorized: user.authorized,
    authenticated: user.authenticated
  };
};
export default connect(mapStateToProps)(BonusBanner);