var __jsx = React.createElement;
/** @format */

import _ from 'lodash';
import React from 'react';
import Router from 'next/router';
import Image from 'next/image';
import SmoothCarouselMobile from "../Carousel/SmoothCarousel/SmoothCarouselMobile";
import EventTrackingHelper from "../../utils/trackingUtilities";
import useWindowAttributes from "../../utils/useWindowDimensions";
import { CategoryCarouselTitle, CategoryCarouselImage, CategoryCarouselItem, CategoryCarouselWrapper } from "./style";
var CategoryCarousel = function CategoryCarousel(_ref) {
  var categories = _ref.categories;
  var CategoriesValid = Array.isArray(categories) && categories.filter(function (category) {
    return category.displayOrder != null;
  });
  var categoriesSorted = _.sortBy(CategoriesValid, 'displayOrder');
  var _useWindowAttributes = useWindowAttributes(),
    isMobile = _useWindowAttributes.isMobile;
  var redirectUrl = function redirectUrl(categoryId) {
    EventTrackingHelper.trackGenericAction("category|".concat(categoryId));
    Router.push("/category?categoryId=".concat(categoryId), "/category/".concat(categoryId));
  };
  return categoriesSorted.length > 0 && isMobile === true ? __jsx(CategoryCarouselWrapper, null, __jsx(SmoothCarouselMobile, {
    sidePadding: "1rem"
  }, categoriesSorted.map(function (category) {
    var displayName = category.displayName,
      categoryId = category.categoryId,
      mobileIcon = category.mobileIcon;
    return __jsx(CategoryCarouselItem, {
      key: categoryId,
      onClick: function onClick() {
        return redirectUrl(categoryId);
      }
    }, __jsx(CategoryCarouselImage, null, __jsx(Image, {
      src: mobileIcon,
      alt: displayName,
      width: 32,
      height: 32
    })), __jsx(CategoryCarouselTitle, null, displayName));
  }))) : null;
};
CategoryCarousel.defaultProps = {
  categories: []
};
export default CategoryCarousel;