import styled from 'styled-components';
var hero = "/static/plugin/hero.png";
var heroMobile = "/static/plugin/hero-mobile.png";
var Firefox = "/static/logos/firefox-logo.svg";
var Chrome = "/static/logos/chrome-logo.svg";
var Safari = "/static/logos/safari-logo.svg";
var Google = "/static/logos/google-logo.svg";
var whiteTick = "/static/icons/icon_tick_white.svg";
import { Container } from "../_global";
import { colours, customFonts, fontSizes, responsiveQuery } from "../../constants/css";
export var Banner = styled.section.withConfig({
  displayName: "style__Banner",
  componentId: "sc-1c84519-0"
})(["background-image:url('", "');background-size:cover;background-position:top;position:relative;height:450px;padding:0 1rem;@media only screen and ", "{background-image:url('", "');}background-repeat:no-repeat;min-height:200px;display:flex;flex-direction:column;align-items:center;justify-content:center;text-align:center;h1{margin:0;font-size:1.625rem;font-family:", ";font-weight:normal;line-height:1.15;@media only screen and ", "{line-height:0.8;font-size:2.5rem;}}p{line-height:1.5;font-weight:normal;max-width:300px;@media only screen and ", "{max-width:645px;font-size:", ";line-height:1.33;}}button{width:270px;}"], heroMobile, responsiveQuery.desktop, hero, customFonts.ciutadellaMed, responsiveQuery.desktop, responsiveQuery.desktop, fontSizes.large);
export var Availability = styled.p.withConfig({
  displayName: "style__Availability",
  componentId: "sc-1c84519-1"
})(["font-size:11px !important;display:flex;align-items:center;position:absolute;bottom:1rem;@media only screen and ", "{font-size:", " !important;display:block;bottom:1.5rem;margin-bottom:0;}"], responsiveQuery.desktop, fontSizes.small);
export var FirefoxLogo = styled.span.withConfig({
  displayName: "style__FirefoxLogo",
  componentId: "sc-1c84519-2"
})(["height:20px;width:20px;background:url('", "') no-repeat;display:inline-block;vertical-align:bottom;margin-left:5px;@media only screen and ", "{height:24px;width:24px;}"], Firefox, responsiveQuery.desktop);
export var ChromeLogo = styled(FirefoxLogo).withConfig({
  displayName: "style__ChromeLogo",
  componentId: "sc-1c84519-3"
})(["background:url('", "') no-repeat;"], Chrome);
export var SafariLogo = styled(FirefoxLogo).withConfig({
  displayName: "style__SafariLogo",
  componentId: "sc-1c84519-4"
})(["background:url('", "') no-repeat;background-size:cover;"], Safari);
export var GoogleLogo = styled(FirefoxLogo).withConfig({
  displayName: "style__GoogleLogo",
  componentId: "sc-1c84519-5"
})(["background:url('", "') no-repeat;background-size:cover;height:39px;width:116px;margin-left:0;@media only screen and ", "{height:39px;width:116px;}"], Google, responsiveQuery.desktop);
export var WhySection = styled.div.withConfig({
  displayName: "style__WhySection",
  componentId: "sc-1c84519-6"
})(["& > div{display:flex;align-items:center;padding:60px 1rem;}h2{font-size:", ";font-family:", ";font-weight:500;font-style:normal;margin:0;text-align:center;line-height:1.21;@media only screen and ", "{text-align:left;font-size:1.625rem;}}img{display:none;@media only screen and ", "{margin-right:38px;display:inline-block;}}svg{vertical-align:middle;margin-right:13px;min-width:20px;}p{margin:0.5rem 0;display:flex;align-items:center;@media only screen and ", "{margin-left:10px;}}"], fontSizes.xxl, customFonts.ciutadellaMed, responsiveQuery.desktop, responsiveQuery.desktop, responsiveQuery.desktop);
export var ContainerStyled = styled(Container).withConfig({
  displayName: "style__ContainerStyled",
  componentId: "sc-1c84519-7"
})(["display:flex;flex-direction:column;justify-content:center;align-items:center;@media only screen and ", "{flex-direction:row;}"], responsiveQuery.desktop);
export var WhyCopyBlock = styled.div.withConfig({
  displayName: "style__WhyCopyBlock",
  componentId: "sc-1c84519-8"
})(["display:flex;flex-direction:column;justify-content:center;max-width:274px;@media only screen and ", "{max-width:100%;}h2{margin-bottom:20px;}"], responsiveQuery.desktop);
export var FindCopyBlock = styled.div.withConfig({
  displayName: "style__FindCopyBlock",
  componentId: "sc-1c84519-9"
})(["display:flex;flex-direction:column;justify-content:center;align-items:center;@media only screen and ", "{align-items:flex-start;}"], responsiveQuery.desktop);
export var HowSection = styled.section.withConfig({
  displayName: "style__HowSection",
  componentId: "sc-1c84519-10"
})(["background-image:linear-gradient(to right,", ",rgba(240,240,240,0.8));padding-top:3rem;padding-bottom:3rem;h3{font-family:", ";font-size:1.625rem;font-weight:normal;text-align:center;margin:0;color:#282b26;}"], colours.qantasWhite, customFonts.ciutadellaMed);
export var HowItems = styled.div.withConfig({
  displayName: "style__HowItems",
  componentId: "sc-1c84519-11"
})(["svg{display:none;@media only screen and ", "{margin-top:4rem;display:block;}}@media only screen and ", "{display:flex;flex-wrap:wrap;justify-content:space-evenly;}"], responsiveQuery.desktop, responsiveQuery.desktop);
export var HowItem = styled.div.withConfig({
  displayName: "style__HowItem",
  componentId: "sc-1c84519-12"
})(["text-align:center;margin-top:24px;"]);
export var HowLogo = styled.img.withConfig({
  displayName: "style__HowLogo",
  componentId: "sc-1c84519-13"
})(["background-color:#ededed;border-radius:50px;width:100px;height:100px;"]);
export var HowTitle = styled.p.withConfig({
  displayName: "style__HowTitle",
  componentId: "sc-1c84519-14"
})(["font-family:", ";font-size:", ";font-weight:500;margin:0.5rem auto;"], customFonts.ciutadellaMed, fontSizes.large);
export var HowCopy = styled.p.withConfig({
  displayName: "style__HowCopy",
  componentId: "sc-1c84519-15"
})(["max-width:310px;margin:auto;text-align:center;@media only screen and ", "{margin:0;max-width:210px;}"], responsiveQuery.desktop);
export var UnmissSection = styled.section.withConfig({
  displayName: "style__UnmissSection",
  componentId: "sc-1c84519-16"
})(["padding-top:50px;padding-bottom:50px;@media only screen and ", "{padding-top:70px;padding-bottom:54px;}h3{font-family:", ";font-size:1.625rem;font-weight:normal;text-align:center;color:#282b26;margin-top:0;margin-bottom:20px;@media only screen and ", "{margin-bottom:44px;}}& > div > div{@media only screen and ", "{justify-content:space-around;}}"], responsiveQuery.tablet, customFonts.ciutadellaMed, responsiveQuery.tablet, responsiveQuery.tablet);
export var UnmissCopy = styled(HowCopy).withConfig({
  displayName: "style__UnmissCopy",
  componentId: "sc-1c84519-17"
})(["margin:1rem auto 2rem;text-align:center;max-width:260px;"]);
export var FindSection = styled(WhySection).withConfig({
  displayName: "style__FindSection",
  componentId: "sc-1c84519-18"
})(["background-image:linear-gradient(to right,", ",rgba(240,240,240,0.8));> div{display:flex;}h2{margin-top:30px;margin-bottom:0;width:280px;@media only screen and ", "{margin-top:0;width:100%;}}p{text-align:center;justify-content:center;width:240px;@media only screen and ", "{text-align:left;justify-content:flex-start;width:68%;margin-left:0;margin-bottom:20px;}}svg{margin:auto;width:100px;@media only screen and ", "{margin:initial;width:116px;}}img{display:block;}"], colours.qantasWhite, responsiveQuery.desktop, responsiveQuery.desktop, responsiveQuery.desktop);
export var InstallSection = styled.section.withConfig({
  displayName: "style__InstallSection",
  componentId: "sc-1c84519-19"
})(["text-align:center;padding:36px 1rem;display:flex;flex-direction:column;justify-content:center;align-items:center;h2{font-size:", ";font-family:", ";font-weight:500;font-style:normal;margin-top:0;text-align:center;line-height:29px;@media only screen and ", "{font-size:", ";line-height:38px;}}h3{font-size:", ";font-family:", ";font-weight:500;font-style:normal;margin-bottom:16px;margin-top:0;@media only screen and ", "{font-size:1.625rem;margin-bottom:11px;}}p{font-size:", ";margin-top:0;width:270px;@media only screen and ", "{font-size:1.375rem;width:100%;}}button{margin:19px 0;width:270px;}"], fontSizes.xxl, customFonts.ciutadellaMed, responsiveQuery.desktop, fontSizes.xxxl, fontSizes.xxl, customFonts.ciutadellaMed, responsiveQuery.desktop, fontSizes.large, responsiveQuery.desktop);
export var InstallAvailabilityCopy = styled(Availability).withConfig({
  displayName: "style__InstallAvailabilityCopy",
  componentId: "sc-1c84519-20"
})(["position:initial;margin-top:2rem;justify-content:center;font-size:", ";"], fontSizes.xs);
export var InstallLogo = styled.img.withConfig({
  displayName: "style__InstallLogo",
  componentId: "sc-1c84519-21"
})(["width:82px;height:82px;border-radius:50px;@media only screen and ", "{width:90px;height:90px;}"], responsiveQuery.desktop);
export var ModalBody = styled.div.withConfig({
  displayName: "style__ModalBody",
  componentId: "sc-1c84519-22"
})(["padding:2rem;text-align:center;"]);
export var SuccessBanner = styled.section.withConfig({
  displayName: "style__SuccessBanner",
  componentId: "sc-1c84519-23"
})(["background:url(", ") no-repeat;background-size:cover;min-height:240px;display:flex;flex-direction:column;align-items:center;justify-content:center;text-align:center;@media only screen and ", "{background:url(", ") no-repeat;background-size:cover;background-position:center;}h1{margin:0;font-size:1.625rem;font-family:", ";font-weight:normal;line-height:1.15;@media only screen and ", "{line-height:0.8;font-size:1.875rem;}}img{margin-bottom:16px;width:86px;height:86px;}"], heroMobile, responsiveQuery.desktop, hero, customFonts.ciutadellaMed, responsiveQuery.desktop);
export var BaseContainer = styled.section.withConfig({
  displayName: "style__BaseContainer",
  componentId: "sc-1c84519-24"
})(["text-align:center;padding:36px 1rem;display:flex;flex-direction:column;justify-content:center;align-items:center;h3{font-size:", ";font-family:", ";font-weight:500;font-style:normal;margin-bottom:16px;margin-top:0;@media only screen and ", "{font-size:1.625rem;margin-bottom:11px;}}p.large{font-size:", ";margin-top:0;width:270px;@media only screen and ", "{font-size:", ";width:100%;}}p{font-size:", ";margin-top:0;width:270px;@media only screen and ", "{font-size:", ";width:100%;}}button{margin:19px 0;width:270px;}button.tickIconGreen{background:", ";background-image:url('", "');background-position:43px;background-repeat:no-repeat;color:", ";font-size:18px;padding:20px 0px 20px 58px;width:370px;letter-spacing:0;}"], fontSizes.xxl, customFonts.ciutadellaMed, responsiveQuery.desktop, fontSizes.large, responsiveQuery.desktop, fontSizes.large, fontSizes.base, responsiveQuery.desktop, fontSizes.base, colours.qantasActiveGreen, whiteTick, colours.qantasWhite);