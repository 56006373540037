var __jsx = React.createElement;
import React from 'react';
import { FeatureFlag } from "../_global";
import { ChromeLogo, FirefoxLogo, SafariLogo } from "./style";

/* TODO: Remove safariFlag and FeatureFlag if
'safari-prompter-info-visible' flag is removed after release */
var safariFlag = 'safari-prompter-info-visible';
var InstallAvailability = function InstallAvailability() {
  return __jsx(React.Fragment, null, "Only available on desktop on", __jsx(ChromeLogo, null), __jsx(FirefoxLogo, null), __jsx(FeatureFlag, {
    flag: safariFlag
  }, __jsx(SafariLogo, null)));
};
export default InstallAvailability;